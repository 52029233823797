@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.param-selected {
    background-color:#efefef;
}

.xparam-selected {
    border: none;
    background-color: rgba(146, 199, 72) !important;
    color: black !important;
}

.param-wide > .param-left-col {
    @apply w-1/3
} 

.param-wide > .param-right-col {
    @apply w-2/3
}

.param-narrow > .param-left-col {
    @apply w-1/2
} 

.param-narrow > .param-right-col {
    @apply w-1/2
}

.td-mask {
    background-color: rgba(0, 0, 0, 0.1);
    @apply w-full h-full absolute inset-0
}

.error {
    border: 1px solid rgba(220,38,38) !important;
}

.nested-view-actions {
    @apply fixed bottom-4 flex items-center
}

header.app-header {
    @apply bg-white border-b h-16 fixed w-full top-0 z-10 flex items-center
}

header.view-header {
    @apply bg-white sticky top-16 z-10
}

.param-input {
    @apply border border-gray-400 rounded p-2 h-10
}

.menu-link {
    @apply font-medium mr-4 p-4 text-blue-800
}

.icon-big {
    @apply h-16 w-16
}

.icon-medium {
    @apply h-8 w-8
}

.icon-small {
    @apply h-6 w-6
}

h1 {
    @apply text-4xl text-gray-700 font-bold
}

.hn-with-icon {
    @apply flex items-center
}

h2 {
    @apply text-xl text-gray-700 font-medium
}



* {
    font-family: 'Ubuntu', sans-serif;
    /* font-family: 'Gotham Pro', sans-serif;  */
    /* font-family: 'Roboto', sans-serif; */
    box-sizing: border-box;
}

html, body {
    padding: 0; 
    margin: 0;
    height: 100%;
}

.app-root {
    min-height: 100%;
    margin: 0 auto;
    /* max-width: 1600px; */
    display: flex;
    align-items: stretch;
}

.bl-button-pending {
    background-image: url(./assets/icons/bars-white.svg);
    background-repeat: no-repeat;
    // background-attachment: fixed;
    background-position: center;
    background-size: 50% 50%;
}

.bl-button-pending > div {
    visibility: hidden;
}

.btn-small {
    cursor: pointer;
}

.btn-small:hover > .btn-icon, .btn-big:hover > .btn-icon {
    display: none;
}

.btn-small:hover > .btn-icon-hover, .btn-big:hover > .btn-icon-hover  {
    display: flex;
}

.btn-icon {
    display: flex;
}

.btn-icon-hover {
    display: none;
}

.input {
    @apply appearance-none rounded block w-full px-2 py-2 border border-gray-400 transition duration-150 ease-in-out
}

.input:focus {
    @apply outline-none border-blue-300
}

.selectiongroup {
    height: auto !important;
    display: flex !important;
}

.selectiongroup > input {
    height: auto !important;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    padding: 0.5rem !important;
}